import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/layout.js";
import WeekOneNav from "../components/rightnavs/weekoneRnav.js";
import WeekOverviewTitle from "../components/schedule_components/weektitle.js";
import { LinkContainer, LinkBox, ExternalLink } from "../components/schedule_components/weeklinks.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DayScheduleWrapper = makeShortcode("DayScheduleWrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout rightnav={<WeekOneNav mdxType="WeekOneNav" />} mdxType="Layout">
    <DayScheduleWrapper mdxType="DayScheduleWrapper">
        <WeekOverviewTitle weeknum={`Week 01`} titleText="The fundamentals of JavaScript" tabTitle={`Week 01`} mdxType="WeekOverviewTitle">
        </WeekOverviewTitle>
        <LinkContainer mdxType="LinkContainer">
            <LinkBox mdxType="LinkBox">
                <h1>Projects</h1>
                <ExternalLink type="project" link="https://docs.google.com/document/d/1-pKEhP6GH5LePEFCw7Hx1fxjDQ_krJlDSKsqtlnonvU/edit?usp=sharing" name="Command Line" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1ZDnlN9eYVB-ZW0h7AFVCRSjYWdeTbEMv8AkGhGHN-mk/edit?usp=sharing" name="Variables" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/135q3vObJcXeFmw6r0Oi4RYyZKFaIC3nXnYyFcpG4D2w/edit?usp=sharing" name="User Input" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1k_TGXWmKf46VbDNJJ3YizH9VEjbqI3m9VV3lTC4hesE/edit?usp=sharing" name="Array" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1lhNNfyDBiMTkBB7NH-zSkQKKI2SIhHgqxTvJF7m25zg/edit?usp=sharing" name="Random" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1AG3dbORAxORc5jht6adfkMp9-mvyCyFUE8oQqnBOTjs/edit?usp=sharing" name="Conditionals I" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1F8GVN1ynTedP2sanDQtO07-f3_2PBn0kCYv6rH31v0w/edit?usp=sharing" name="While Loops" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1J2XvoN0srbaoJoTG5o939bsipcDtQ_ZEdYK1l6Y6w-g/edit?usp=sharing" name="For Loops" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1s-BPw8-vVpKeKG_lWrvGHzENQhOOY5o-_MJI6L5dspU/edit?usp=sharing" name="Functions" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1BPeeAE_ZYVvlm9DRX1m5Azprq0zcsu2yLZ_4ID5858g/edit?usp=sharing" name="Parameters" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/1gAS9i37G6MgI15hZxYO747UExKjD9Lc2PiRBXX1U4Ak/edit?usp=sharing" name="Return" mdxType="ExternalLink" />
                <ExternalLink type="project" link="https://docs.google.com/document/d/18NSO7zbcKAQ_oB1kHCy8x5oU3oszd7DC8X05WdTRlBk/edit?usp=sharing" name="M.A.S.H." mdxType="ExternalLink" />
            </LinkBox>
            <LinkBox mdxType="LinkBox">
                <h1>Lectures / Codealongs</h1>
                <ExternalLink type="lecture" name="Lecture: Command Line" link="https://docs.google.com/presentation/d/1qiGYkocBKoIZhhRmlQcXvfbn4EzhgPUDfEGxabjk79s/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Command Line" link="https://docs.google.com/document/d/1IMuEZvqy6PpefvIk6K0hRNmJ6eyMZPb6w2tcT3cmRB4/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Variables" link="https://docs.google.com/presentation/d/1IChb4s7WiOt0TNAZ8_fwT855vvYYoWV33lFf-xWQ748/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Variables I" link="https://docs.google.com/document/d/1hkON_VMaP3mJN-G0zGCLQxDoqhZIaCluGXNFqkRguYI/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Variables II" link="https://docs.google.com/document/d/1hkON_VMaP3mJN-G0zGCLQxDoqhZIaCluGXNFqkRguYI/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: User Input" link="https://docs.google.com/presentation/d/1l9bRNaw71yIInHlnB8EU7gf0Dva1ZUP1YuWMPNIzhVg/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: User Input" link="https://docs.google.com/document/d/12JL8QG2FMNzIUDFkaddg12zExABPxKAC3Jusp3L_lZ8/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Debugging" link="https://docs.google.com/document/d/1p60kVs-f8WgicQibUCGBSzg28Jmbbzdw-pQq2_02r3c/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Array" link="https://docs.google.com/presentation/d/1eB0_ozo3SnIlpvvrd056mEgVnfeecANqSUVW_Oove_g/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Array" link="https://docs.google.com/document/d/1U3ibW4nSuvwirCDSBngm6l9ju0aECreo8bsyVP_mXb8/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Random" link="https://docs.google.com/presentation/d/1T4ppns9WU7Nt5lOjwNB4tkxURB8dt0REzRooQDAAMKc/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Random" link="https://docs.google.com/document/d/17gvDYvhRDlsYINa5fn64zTNM5gbqt0ONjm59Tq-CG9g/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Conditionals" link="https://drive.google.com/open?id=1648UT-SFNYlz3ZnTmbzqcC08yt49ter5l3g-OjdxZOU" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Conditionals I" link="https://drive.google.com/open?id=1_GG9Gldp28CFvJGE0Soq0kJTZcccsHwUw_zZXiiBdJI" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Conditionals II" link="https://drive.google.com/open?id=1Lu4xgiZEvX0eFRaoIjvcdj3tB13_OoZGtYT8uaj63kY" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: While Loops" link="https://docs.google.com/presentation/d/1oJd5X80z2Ar_QPaQJaHX_U72ZqmD3koQjSXAoRZSstc/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: While Loops" link="https://docs.google.com/document/d/1lt5SyRnX94ET3LseNDPORjjgToglZIw2WIpErmu4UxI/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: For Loops" link="https://docs.google.com/presentation/d/1bOc8hcy86fkglbv1jPqSBZ-9irlcTqvvnndTmlhDW8Q/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: For Loops" link="https://docs.google.com/document/d/1aW9nbO5UCb9AVi8GC4Jyx9DahPYovgBHWh4XcS_ND1U/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Functions" link="https://docs.google.com/presentation/d/13oMNOcmrdEdcDjArha3Fg4TR0Yet4bi5ePEqmdNmxWw/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Introducing Functions" link="https://docs.google.com/document/d/1CZLtDCZaS-w59P3ave5bexkQBpUCWZpM-Szuiq9fBVQ/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Vanilla JavaScript Art" link="https://docs.google.com/document/d/1AI7DSLIjkKl2k5lU400FLmFrvk_AseZM0kmhE_DLv8Y/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Parameters" link="https://docs.google.com/document/d/19cCDDE_SJVlka6ddHDa0arXXwrujAL3eOV4SQnUiUlU/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Return" link="https://docs.google.com/document/d/1LVztCkLaJJYvn8OtsNerpddhJWe9i4iuks38EmKWe88/edit?usp=sharing" mdxType="ExternalLink" />
            </LinkBox>
            <LinkBox mdxType="LinkBox">
                <h1>Core Skills</h1>
            </LinkBox>
        </LinkContainer>
    </DayScheduleWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      